<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('daili_xinzeng') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-sheet
          color="#fff"
          class="pa-3 my-3 rounded-xl"
        >
          <v-text-field
            v-model="username"
            filled
            rounded
            background-color="app_bg"
            :label="$t('username')"
            hide-details="auto"
            class="mb-3"
          />
          <v-text-field
            v-model="password"
            filled
            rounded
            background-color="app_bg"
            :label="$t('password')"
            hide-details="auto"
            :append-icon="pw_show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="pw_rules"
            :type="pw_show ? 'text' : 'password'"
            class="mb-3"
            @click:append="pw_show = !pw_show"
          />
          <v-textarea
            v-model="remark"
            filled
            rounded
            background-color="app_bg"
            :label="$t('beizhu')"
            hide-details="auto"
          />
        </v-sheet>

        <v-btn
          :disabled="!valid"
          block
          x-large
          rounded
          color="primary"
          class="mt-5"
          @click.stop="addagent"
        >
          {{ $t('tijiao') }}
        </v-btn>
      </v-form>
    </v-container>

    <v-dialog
      v-model="add_done"
      max-width="300"
    >
      <div class="text-right">
        <v-btn
          fab
          small
          dark
          fixed
          class="mt-n5 ml-n5 opacity-7"
          @click="add_done = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card class="rounded-xl">
        <div class="text-center py-6">
          <v-avatar
            color="primary"
            size="64"
            class="rounded-xl"
          >
            <v-icon
              dark
              large
            >
              mdi-check-bold
            </v-icon>
          </v-avatar>
          <div class="text-h5 text-center primary--text pt-3">
            {{ $t('chuangjianchenggong') }}
          </div>
        </div>
        <v-divider class="opacity-3" />

        <v-card-text ref="adddone">
          <v-list-item>
            <v-list-item-content class="">
              {{ $t('username') }}
            </v-list-item-content>
            <v-list-item-action>
              {{ username_copy }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="">
              {{ $t('password') }}
            </v-list-item-content>
            <v-list-item-action>
              {{ password_copy }}
            </v-list-item-action>
          </v-list-item>

          <v-btn
            block
            outlined
            large
            rounded
            color="primary"
            dark
            @click="doCopy(username_copy+'\n'+password_copy)"
          >
            {{ $t('copy_one') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'AddAgent',
    components:{

    },
    data () {
      return {
        valid: true,
        username: '',
        password: '',
        remark: '',
        add_done: false,
        pw_show: false,
        pw_rules: [
          v => !!v || 'Required',
          v => (v && v.length >= 8) || 'Min 8 characters',
        ],
        username_copy: '',
        password_copy: '',
      }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    beforeDestroy () {
      
    },
    methods: {
      addagent() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          username: this.username,
          password: this.password,
          remark: this.remark,
        }
        this.$server.addagent(paramObj).then((response) => {
          if(response.code==200){
            this.username_copy = this.username
            this.password_copy = this.password
            this.$refs.form.reset()
            this.add_done = true
          }else{
            this.$snackbar.warning(response.msg)
          }
        })
      },
      doCopy: function (text) {
        text = ''+text//转string
        this.$copyText(text, this.$refs.adddone).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    }
  }
</script>